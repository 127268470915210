.listCard {
    background-color: #1c2035;
    border-radius: 20px;
}
.listContainer {
    width: 100%;
    border-radius: inherit;
    padding: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.listContainer > div {
    width: 25%;
}
.listContainer > div:first-child  {
    width: 30%;
}
.listContainer > div:last-child  {
    width: 20%;
}
.listContainer > div > div {
    text-align: center;
    color: #FFF;
}
.listContainer > div > div:first-child {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
}
.listContainer > div > div:last-child {
    font-size: 15px;
    letter-spacing: .3px;
}
.result {
    font-size: 20px !important;
    font-weight: 600;
}
