.privacyContainer {
    height: 100%;
    /* padding: 10px 0; */
    width: 100%;
}

.privacyContainer p {
    font-size: 14px;
    text-align: justify;
    margin-bottom: 10px;
}