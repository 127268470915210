.liveContainer {
    padding-bottom: 15px;
    /* border-bottom: 1px solid #ffffff41; */
    background-color: #FFF;
    border-radius: 20px;
}
.time {
    text-align: center;
    user-select: none;
    font-size: 13px;
    margin-top: 10px;
    color: #1c2035;
    display: flex;
    align-items: center;
    justify-content: center;
}
.liveNum {
    font-size: 90px;
    /* font-weight: bold; */
    font-weight: 500;
    user-select: none;
    text-align: center;
    color: var(--text-color);
    text-shadow: 2px 2px 5px var(--main-color);
}
.blinkText {
    animation: blink 3s infinite;
}
.liveStock {
    width: 100%;
    display: flex;
    align-items: center;
    color: #1c2035;
}
.liveStock div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.liveStock div p:first-child {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
}
.liveStock div p:last-child {
    font-size: 15px;
    font-weight: 600;
}

.historyContainer {
    height: 100%;
    margin-top: 10px;
    overflow: hidden scroll;
}

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}