.tabsContainer {
    display: flex;
    position: relative;
    margin: auto;
    border: 1px solid #ccc;
    background-color: #FFF;
    padding: 5px;
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
  
  .tabUnderline {
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: -10;
    display: flex;
    overflow: hidden;
    border-radius: 7px;
    padding: 5px 0;
    transition: all 0.3s ease-in-out;
  }
  
  .tabUnderlineInner {
    height: 100%;
    width: 100%;
    border-radius: 7px;
    background-color: var(--text-color);
  }
  
  .tabButton {
    /* margin: auto; */
    cursor: pointer;
    user-select: none;
    /* border-radius: 50%; */
    /* padding: 0 1rem; */
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #484330;
    background-color: transparent;
    border: none;
    padding: 6px 22px;
  }
  
  .inactiveTab:hover {
    color: #a3a3a3;
  }
  