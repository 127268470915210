.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
}
.sidebarBackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
    background: #fff;
    z-index: 20;
}
.toggleButton {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    z-index: 20;
  }

  .listContainer {
    padding: 5px;
    position: absolute;
    top: 65px;
    width: 260px;
    z-index: 20;
  }
  .listContainer li {
    margin-bottom: 5px;
  }

  .listContainer li:last-child {
    margin-bottom: 0px;
  }
  
  .list {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .listFlex {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 5px;
      border-radius: 5px;
      transition: 0.5s;
  }

  .list .listFlex:hover {
    background-color: #eee;
  }

  .active {
    background-color: #eee;
  }

  .label {
    color: #2b2738;
    font-size: 15px;
    margin-left: 10px;
  }
/* 
.sidebarHeader {
    width: 100%;
    height: 60px;
    background-color: #fff;
}

.headerFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
} */