.editContainer {
    position: relative;
    padding-right: 45px;
    display: flex;
    align-items: center;
}
.editIcon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    padding-top: 5px;
}
.input {
    padding: 3px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.5px;
}