.backDrop {
    background-color: #00000080;
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 480px;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card {
    width: 360px;
    max-width: 90%;
    background-color: #FFF;
    border-radius: 5px;
    padding: 15px;
}

.cardHeader {
    color: #2b2738;
    padding: 0 0 10px 0;
}

.content {
    color: red;
    margin: 10px 0;
}

.buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.buttonGroup button {
    padding: 12px 20px;
    width: 100%;
    cursor: pointer;
}
.buttonGroup button:first-child {
    margin-right: 10px;
    border: 1px solid tomato;
    border-radius: 5px;
    background-color: tomato;
    color: #FFF;
}
.buttonGroup button:last-child {
    margin-right: 10px;
    border: 1px solid green;
    border-radius: 5px;
    background-color: green;
    color: #FFF;
}
.cardFooter {
    color: #2b2738;
    padding: 10px 0 0 0;
}