.homeContainer {
    min-height: 100%;
    background-color: #2b2738;
    padding: 80px 30px 30px 30px;
    position: relative;
}

.title {
    color: var(--accent-color);
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
}

.createButton {
    border: 1px solid green;
    background-color: green;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table td {
    border: 1px solid #e8e8e8;
    border-width: 0.2px;
    color: #e8e8e8;
    font-weight: 300;
    width: 50%;
    text-align: center;
    padding: 5px;
}
.table td:first-child img {
    width: 90px;
    height: 30px;
    object-fit: cover;
    border-radius: 5px;
}
.table td:last-child {
    color: var(--accent-color);
}