.header {
    height: 60px;
    background-color: #1c2035;
    position: fixed;
    width: 100%;
    max-width: 480px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    /* border-radius: 0 0 10px 10px; */
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.logoName {
    font-size: 19px;
    user-select: none;
    color: #FFF;
}
.title {
    font-size: 15px;
    user-select: none;
    color: #FFF;  
}
.history {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.history img {
    width: 100%;
}
.back {
    color: var(--text-color);
    cursor: pointer;
}

.historyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.appDownLoadButton {
    background-color: #fee300;
    border-radius: 0.375rem;
    padding: 0.25rem;
    color: #7a0808;
    border: 1px solid #fee300;
    cursor: pointer;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
/* .back:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
} */