.inputContainer {
    position: relative;
    margin-bottom: 15px;
}
.input {
    width: 100%;
    outline: none;
    font-size: 16px;
    letter-spacing: .5px;
    border: 1px solid #ffffff47;
    border-radius: 6px;
    color: #ffffffbd;
    background-color: #3b364c;
    transition: 0.5s;
}
.input:focus {
    border-color: var(--accent-color); 
}
.icon {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translate(0, -50%);
    cursor: pointer;
}