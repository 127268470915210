.loginContainer {
    height: 100%;
    background-color: #2b2738;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.back {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
 }
.loginCard {
    width: 90%;
}
.logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.logo img {
    width: 170px;
    border-radius: 10px;
}
.submitBtn {
    background-color: var(--accent-color);
    border-radius: 6px;
    width: 100%;
    padding: 13px;
    border: 2px solid transparent;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    color: #FFF;
    margin-top: 20px;
    letter-spacing: .5px;
    user-select: none;
}