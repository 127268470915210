.homeContainer {
    height: 100%;
    background-color: #2b2738;
    padding: 70px 50px 30px 50px;
    position: relative;
}
.title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 15px;
}

.resultInput {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.resultInput input {
    padding: 12px 60px 12px 20px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    font-size: 16px;
    letter-spacing: 0.5px;
}
.resultInput input::placeholder {
    color: var(--accent-color);
}
.icon {
    transform: rotate(45deg) translate(0, -50%);
    position: absolute;
    top: 43%;
    right: 25px;
    cursor: pointer;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table td {
    border: 1px solid #e8e8e8;
    border-width: 0.2px;
    color: #e8e8e8;
    font-weight: 300;
    width: 50%;
    text-align: center;
    padding: 5px;
}
.table td:last-child {
    color: var(--accent-color);
}
.times {
    padding: 13px 60px;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    outline: none;
    color: #000;
    -webkit-appearance: none;
     -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: 10px;
    font-size: 16px;
}
.history {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.history img {
    width: 100%;
}

.switchContainer {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 23px;
    margin-left: 20px;
}
.switchInput {
    display:none;
    &:checked + .slider {
        background-color: #2ab934;
    }
    &:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(38px);
    }
    &:checked + .slider:after {
        content:'ON';
    }
}
.switchToggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;

    &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: white;
        transition: 0.4s;
        border-radius: 6px;
    }
    &:after {
        content:'OFF';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 48%;
        left: 51%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
    }
}