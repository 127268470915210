.container {
    width: 100%;
    max-width: 480px;
    height: 100vh;
    background-color: #090a1f;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.content {
    height: 100%;
    overflow: hidden auto;
}